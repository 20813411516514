import React, { Fragment } from "react";
import { Layout, SEO } from "src/components";
import {
  HeroTemplate,
  PressSectionTemplate,
  PressKitTemplate
} from "src/services/prismic/templates";
import { graphql } from "gatsby";
import { PrismicPage } from "src/graphqlTypes";

type PressProps = {
  data: {
    prismicPage: PrismicPage;
  };
};

const Press = ({ data: { prismicPage: page } }: PressProps) => {
  return (
    <Fragment>
      <SEO
        title={page.data?.title.text}
        description={page.data?.description.text}
        path="/press"
      />

      <Layout>
        {page?.data?.body?.map((slice, idx) => {
          switch (slice.__typename) {
            case "PrismicPageBodyHero":
              return <HeroTemplate data={slice} key={idx} />;
            case "PrismicPageBodyPressSection":
              return <PressSectionTemplate data={slice} key={idx} />;
            case "PrismicPageBodyPressKit":
              return <PressKitTemplate data={slice} key={idx} />;
            default:
              return null;
          }
        })}
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  query {
    prismicPage(uid: { eq: "press" }) {
      data {
        title {
          text
        }
        description {
          text
        }
        body {
          ...PrismicHero
          ...PrismicPressSection
          ...PrismicPressKit
        }
      }
    }
  }
`;

export default Press;
